

@media only screen and (max-width: 768px) {
  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.App {
  background-color: #F5EFE8;
}

.header-title {
  font-size: 30px;
  text-align: center;
  background-color: white;
  padding: 10px 0;
  border-bottom: 2px solid #6E4317;
  font-weight: 700;
  color: #6E4317;
}


.nav-links > li > a{
  text-decoration: none;
  color:#6E4317;
  font-weight: 700;
  font-size: 20Px;
}

.nav-links > li {
  display: none;
  margin: auto 0;
  list-style: none;
}

.logo {
  width: 70px;
  height: auto;
}

img {
  margin-left: 0px;
}

.nav {
  color: #6E4317;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
}

.nav-links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 100px;
}


.nav-contact {
  padding: 5Px 20px;
  font-weight: 700;
  margin: auto 0;
  background-color:#8B6A4B;
  border: none;
  font-size: 25px;
  text-decoration: none;
  color: white;
}

.nav-contact:hover {
  background-color: #6E4317;
}

.carousel-item {
  margin: 0px;
  height: 500px;
}

.carousel {
  height: 500px !important;
  /* display: none; */
  /* margin-top:5px ; */
  z-index: 0;
}

.carousel-h2 {
  position: absolute;
  z-index: 1;
  top: 350px;
  left: 30%;
  width: auto;
  color: white;
  display: none;
  font-size: 90px;
}

.sec {
  margin: 0;
}

section {
  margin-top: 150px;
  gap: 50px;
}


.card-title {
  background-color: #8B6A4B;
}

.card > a {
  text-decoration: none;
}

.card-title > p {
  font-size: 20px ;
  color: white;
  margin: 10Px 0;
  margin-left: 15px;
}

.card {
  
}

.card > img {
  width: 304px !important;
}

.card-section {
  display: grid ;
  gap: 50px;
}

.button-section > a {
  background-color: grey;
  border: none;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.badges-section {
  margin-top: 150px;
  gap: 50px;
  display: grid ;
}

.badges-section > img {
  width: 200px !important;
  height: 200px !important;
}

.trust-section {
  margin-top: 150Px;
  gap: 50px;
  display: grid;
  justify-content: center;
}

.trust-section-1 {
  margin-top: 100Px;
  gap: 50px;
  display: grid;
  justify-content: center;
}

.text-section {
  margin: auto 0;
  padding: 0 20px;
  /* margin-left: auto; */
  /* margin-right: 150px; */
}

.text-section-1 {
  margin: auto 0;
  padding: 0 20px;
  /* margin-left: auto;
  margin-right: 300px; */
}

.text-section-1 > h2{
  font-size: 60px;
  color: #6E4317;
  font-weight: 700;
}

.image-section {
  justify-content: center;
  display: flex;
}

.image-section > img{
  width: 300px !important;  
  height: 200px !important;  
}

.text-section-1 > p{
  font-size: 20px;
  font-weight: 400;
  margin-top: 50Px;
}

.text-section > h2{
  font-size: 60px;
  color: #6E4317;
  font-weight: 700;
}

.text-section > p{
  font-size: 20px;
  font-weight: 400;
  margin-top: 50Px;
}




.dropdown {
  /* padding: 0px !important; */
  height: 50px !important;
  display: flex;
  /* margin-bottom: 50px; */
  justify-content: center;
}

.btn{
  background-color: #6E4317 !important;
  height: 47px !important;
  width:130px;
}

.dropdown-menu {
  background-color:#6E4317 !important;
  /* border: 2px solid #6E4317 !important; */
}

.dropdown-item {
  color:#ffffff !important;
}
.active {
  background-color: #8B6A4B !important;
}

.dropdown-item:hover {
  background-color:#8B6A4B !important;
}

.product-page-name{
  justify-content: center;
  display: grid;
}

.product-page-name > hr{
  width: 300px;
  height: 5px;
  background-color: #6E4317;
  border: none;
}

.product-page-name > h2 {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  /* display: flex; */
  margin: 0px;
  color: #000000;
}

.product-page-name > h2 > span{
  display: none;
}

.product-section {
  display: flex;
  padding-bottom: 100px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  margin-top: 100px;
}

.product{
  width: auto;
}

.card-body {
  background-color: #8B6A4B;
  text-decoration: none;
}

.card-title-sec {
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color:#8B6A4B;
  text-align: center;
  /* width: 250px !important; */
}

.card-title-sec > p {
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin: 0 ;
}

.card-title{
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color:#8B6A4B;
  text-align: center;
  width: 300px !important;
}

.card-title > span > a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 30px;
}

.card-title > p {
  color: white;
  font-weight: 600;
  font-size: 30px;
  margin: 0px;
}

.card-title:hover{
  background-color: #6E4317;
}

.card-img {
  border-radius: none;
  height: 250px;
  width: 300px !important;
}

.card-title > span {
  color: white;
  font-weight: 600;
  font-size: 20px;
}

.product-o-links {
  list-style: none;
  display: grid;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  background-color: 	white;
  /* border: 2px solid black; */
  border-radius: 40Px;
  padding: 30Px 20px;
  gap: 50Px;
  width: 300px;
  font-size: 20px;
}

.product-title-o {
  margin-top: 150px;
  display: flex;
  height: auto;
  margin-bottom: 20px;
  gap: 0px;
  justify-content: center;
}

.product-o-section {
  margin-top: 100px;
  display: grid;
  height: auto;
  margin-bottom: 20px;
  gap: 30px;
  justify-content: center;
}


.product-o-links > li > a {
  color: #6E4317;
  text-decoration: none;
  font-weight: 700;
  padding: 0 10px;
}

.product-o-links > li > .active-link{
  text-decoration: underline;
}

.mobile-none {
  display: none;
}

.product-title {
  text-align: center;
  font-size: 40px;
  margin-top: 0px;
  color: #6E4317;
  font-weight: 800;
}

.goback-btn {
  margin-top: 50px;
  margin-left: 50px;
  width: 100px;
}

.goback-btn:hover {
  background-color:#6E4317;
  color: #F5EFE8;
}

.goback-btn:focus {
  background-color:#8B6A4B;
  color: #F5EFE8;
}


.product-info-section {
  justify-content: center ;
  display: grid;
  margin-top: 100px;
  padding: 0 20px;
  padding-bottom: 200px;
}

.product-info-section > img {
 width: 300Px !important;
 height: 300px !important;
 margin: 0 auto;
}


.product-info > h2 {
  font-size: 40px;
  margin-top: 40px;
  font-weight: 700;
  color: #6E4317;
}
.product-info > a {
  text-decoration: none
}

.product-info > a > span {
  font-size: 18px;
  text-decoration: underline;
  font-weight: 700;
  color: #6E4317;
}

.product-info-img > img {
  /* margin-left: 200px; */
  width: 300px !important;
  height: 300px !important;
}

.product-info-img {
  display: grid;
  justify-content: center;
}

.product-info > h3 {
  font-size: 30px;
  font-weight: 400;
  font-style: italic;
  color: #6E4317;
}

.product-info > h3 >span {
  font-weight: 700 !important;
  font-style: normal;
  color: #6E4317;
}

.product-info > p {
  color: #6E4317;
  margin-top: 30px;
  font-size: 20px;
}


.product-info-button{
  font-size: 20px;
  background-color: #8B6A4B;
  color: white;
  padding: 10Px 15px;
  position: absolute;
  margin-top: 40px;
  text-decoration: none;
}

.other-images {
  display: grid;
  justify-content: center;
  gap: 100Px;
}


.product-info-button:hover{
  background-color: #6E4317;
}

.contact-info {
  display: grid;
  justify-content: center;
  gap: 50px;
  margin-top: 100px;
}


.contact > h1 {
  font-size: 50Px;
  text-align: center;
  margin-top: 100px;
  font-weight: 800;
}

.contact-info> :nth-child(1){
  font-weight: 600;
  font-size:20Px
}

.contact-info> :nth-child(2){
  font-size:20Px

}

.next-page {
  justify-content: center;
  display: flex;
  margin-top: 10px;
}

.next-button {
  display: flex;
  /* gap: 5px; */

}

.next-button > a {
  /* background-color: gray; */
  padding: 5Px;
  width: 80px;
  text-align: center;
  color:white;
  text-decoration: none;
  font-weight:700 ;
}

.rotate {
  rotate: 180deg ;
}

.next-numbers {
  gap: 20px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

#active {
  text-decoration: underline;
  font-weight: 900;
}

.next-numbers > a {
  color: #6E4317;
  text-decoration: none;
  font-weight: 600;
}

.footer {
  margin-top: 100Px;
  background-color:#8B6A4B;
  height: 350px !important;
  gap: 10Px;
  display: grid;
  justify-content: center;
  width: auto;
}

.footer-logo {
  margin: auto 0 ;
  padding-left: 30Px;
  /* height: auto; */
}

.footer-logo > img {
  /* display: none; */
  width: 80px;
}

.footer > ul {
  color: white;
  font-size: 15px;
  margin: auto 0;
  padding:0 20Px ;
}


.footer > ul{
  display: grid;
  gap: 60px;
}

.footer > ul> .f-link{
  display: grid;
}

.footer > ul> .f-link > a{
  color: white;
  text-decoration: underline;
}

.footer > ul> .f-link > a:hover{
  text-decoration-color:#6E4317;
}

  .container {
    display: grid;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .container > h2 {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
  }
  
  .contact-form {
    margin-top: 50px;
    display: grid;
    margin-bottom: 200px;
    height: 300px;
    justify-content:  center ;
    width: 300px ;
  }
  
  .contact-form > input, textarea, button {
    padding: 10px;
    margin: 5px 0;
    width: 300px ;
    resize:none;
    background-color: #F5EFE8;
    border: 2px solid #6E4317;
  }

  
  .contact-form > button {
    margin-top: 5px;
    background-color:#8B6A4B ;
    color: #F5EFE8;
    border: none;
    font-weight: 700;
  }
  
  .contact-form > button:hover {
    background-color:#6E4317 ;
  }
  
  .howto-img {
    width: 300px ;
    border: 2px solid black m ;
  }

  .howto-div {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .check-h3 {
    text-align: center;
  }
  

  textarea {
    padding-bottom: 50px;
  }
  
}




