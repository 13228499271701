@media only screen and (min-width: 1200px){

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.App {
  background-color:#F5EFE8
}

.header-title {
  font-size: 30px;
  text-align: center;
  background-color: white;
  padding: 10px 0;
  font-weight: 700;
  color: #6E4317;
  display: none;
}


.nav-links > li > a{
  text-decoration: none;
  color:#6E4317;
  font-weight: 700;
  font-size: 20Px;
}

.nav-links > li {
  margin: auto 0;
  list-style: none;
}

.logo {
  width: 70px;
  height: auto;
}

img {
  margin-left: 0px;
}

.nav {
  color: #6E4317;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
}

.nav-links {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 100px;
}


.nav-contact {
  padding: 5Px 20px;
  font-weight: 700;
  margin: auto 0;
  background-color: #8B6A4B;
  border: none;
  font-size: 25px;
  text-decoration: none;
  color: white;
}

.nav-contact:hover {
  background-color: #6E4317;
}

.carousel-item {
  margin: 0px;
}

.carousel {
  /* margin-top:5px ; */
  z-index: 0;
}

.carousel-h2 {
  position: absolute;
  z-index: 1;
  top: 350px;
  left: 0%;
  text-align: center;
  right: 0%;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  color: white;
  font-size: 90px;
}

.section {
  margin-top: 200px;
}

.card-title {
  background-color: #8B6A4B;
}

.card > a {
  text-decoration: none;
}

.card-title > p {
  font-size: 30px;
  color: white;
  margin: 10Px 0;
  margin-left: 15px;
}

.card-section {
  gap: 150px;
  display: flex;
}

.button-section > a {
  background-color: grey;
  border: none;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  font-size: 30px;
}

.badges-section {
margin-top: 150px;
display: flex;
gap: 100px;
}

.trust-section {
  margin-top: 300Px;
  gap: 200px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.trust-section-1 {
  margin-top: 100Px;
  gap: 150px;
  display: flex;
  justify-content: center;
}

.text-section {
  padding-left:100px ;
  margin: auto 0;
  /* margin-left: auto; */
  /* margin-right: 150px; */
}

.text-section-1 {
  padding-right:100px ;
  margin: auto 0;
  /* margin-left: auto;
  margin-right: 300px; */
}

.text-section-1 > h2{
  font-size: 60px;
  color: #6E4317;
  font-weight: 700;
}

.text-section-1 > p{
  font-size: 20px;
  font-weight: 400;
  margin-top: 50Px;
}

.text-section > h2{
  font-size: 60px;
  color: #6E4317;
  font-weight: 700;
}

.text-section > p{
  font-size: 20px;
  font-weight: 400;
  margin-top: 50Px;
}


.dropdown {
  /* padding: 0px !important; */
  height: 1px !important;
}

.btn{
  background-color: #6E4317 !important;
  height: 47px !important;
  width:130px;
}

.dropdown-menu {
  background-color:#6E4317 !important;
  /* border: 2px solid #6E4317 !important; */
}

.dropdown-item {
  color:#ffffff !important;
}
.active {
  background-color: #8B6A4B !important;
}

.dropdown-item:hover {
  background-color:#8B6A4B !important;
}

.product-page-name{
  justify-content: center;
  display: grid;
}

.product-page-name > hr{
  width: 700px;
  height: 5px;
  background-color: #6E4317;
  border: none;
}

.product-page-name > h2 {
  font-weight: 700;
  /* display: flex; */
  margin: 0px;
  color: #000000;
}

.product-o-section {
  margin-top: 150px;
  display: flex;
  gap: 100px;
  justify-content: center;
}

.product-o-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: 	white;
  border: 2px solid black;
  border-radius: 40Px;
  padding: 30Px 100px;
  gap: 20Px;
  width: 1000px;
  font-size: 20px;
}

.product-o-links > li > a {
  color: #6E4317;
  text-decoration: none;
  font-weight: 700;
  padding: 0 10px;
}

.product-o-links > li > .active-link{
  text-decoration: underline;
}

.pc-none {
  display: none;
}


.product-section {
  display: flex;
  padding-bottom: 200px;
  padding: 0 30px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  margin-top: 150px;
}


.product{
  transition: transform .2s; 
  width: 350px;
  margin: 0 auto;
}

.product:hover {
  transform: scale(1.1); 
}

.card-body {
  background-color: #8B6A4B;
  text-decoration: none;
}

.card-title{
    /* display: none; */
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color:#8B6A4B;
  text-align: center;
  font-weight: 600;
}

.other-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100Px;
  margin: 0 20px;
}

.card-title:hover{
  background-color: #6E4317;
}

.card > img {
  object-fit: cover;
  height: 300px;
}

.card-img {
  border-radius: none;
  border: 2px solid #8B6A4B;
}

.card-title-sec {
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color:#8B6A4B;
  text-align: center;
  /* width: 250px !important; */
}

.card-title-sec > p {
  color: white;
  font-weight: 600;
  font-size: 30px;
  margin: 0 ;
}


.card-title > span > a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 30px;
}

.card-title > p {
  color: white;
  font-weight: 600;
  font-size: 30px;
  margin: 0px;
}

.product-title {
  text-align: center;
  font-size: 40px;
  /* margin-top: 150px; */
  color: #6E4317;
  margin: 0px;
  font-weight: 800;
}

.goback-btn {
  margin-top: 50px;
  margin-left: 50px;
  width: 100px;
}

.goback-btn:hover {
  background-color:#6E4317;
  color: #F5EFE8;
}

.goback-btn:focus {
  background-color:#8B6A4B;
  color: #F5EFE8;
}


.product-info-section {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  gap: 150px;
  padding-bottom: 200px;
}

.product-info {
  margin-right:100px;
}
.product-info-img {
  display: grid;
  margin-left: 200px;
}

.product-info > h2 {
  font-size: 40px;
  font-weight: 700;
  color: #6E4317;
}


.product-info > p {
  word-break:normal;
  color: #6E4317;
  margin-top: 30px;
  font-size: 20px;
}

.product-info > a > span {
  font-size: 20px;
  text-decoration: underline;
  font-weight: 700;
  color: #6E4317;
}

.price {
  font-size: 25Px !important;
  font-weight: 700;
  font-style: italic;
  color: #6E4317;
}

.product-info > h3 {
  font-size: 30px;
  font-weight: 400;
  font-style: italic;
  color: #6E4317;
}

.product-info > h3 >span {
  font-weight: 700 !important;
  font-style: normal;
  color: #6E4317;
}



.product-info-button{
  font-size: 20px;
  background-color: #8B6A4B;
  color: white;
  padding: 10Px 15px;
  position: absolute;
  margin-top: 40px;
  text-decoration: none;
}

.product-info-button:hover{
  background-color: #6E4317;
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 200px;
  margin-top: 200px;
}


.contact > h1 {
  font-size: 60Px;
  text-align: center;
  margin-top: 100px;
  font-weight: 800;
}

.contact-info> :nth-child(1){
  font-size:20Px
}

.contact-info> :nth-child(2){
  font-size:20Px
}



.footer {
  margin-top: 200Px;
  background-color:#8B6A4B;
  height: 200px !important;
  padding: 30Px 0px;
  gap: 500px;
  display: flex;
  justify-content: center;
  width: auto;
}

.footer-logo {
  margin: auto 0 ;
  /* height: auto; */
  
}

.footer-logo > img {
  width: 120px;
}

.footer > ul {
  color: white;
  font-size: 20px;
  margin: auto 0;
}

.footer > ul{
  display: flex !important;
  gap: 100px !important;
}

.footer > ul> .f-link{
  display: grid;
}

.footer > ul> .f-link > a{
  color: white;
  text-decoration: underline;
}

.footer > ul> .f-link > a:hover{
  text-decoration: underline;
  text-decoration-color:#6E4317 ;
}



.check-out > h1 {
  font-size: 40Px;
  text-align: center;
  margin-top: 100px;
  font-weight: 800;
}

.check-out > h3 {
  font-size: 30Px;
  text-align: center;
  margin-top: 50px;
  font-weight: 500;
}

.check-out > h3 > span{
  font-size: 30Px;
  text-align: center;
  color: #6E4317;
  margin-top: 50px;
  font-weight: 800;
}

.check-out > h3:nth-of-type(2){
  font-size: 40Px;
  text-align: center;
  margin-top: 100px;
  font-weight: 700;
}

.check-out > p {
  text-align: center;
  font-weight: 700;
}

.check-example {
  padding: 30px;
  border: 2px solid black;
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
}

.check-ex-li > span {
  font-weight: 800;
  color: #6E4317;
}

.check-example > li{
  list-style: none;
  font-size: 20Px;
  font-weight: 700;
  color: gray;
}

.howto-div {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}


.next-page {
  justify-content: center;
  display: flex;
  margin-top: 50px;
}

.next-button {
  display: flex;
  /* gap: 5px; */

}

.next-button > a {
  /* background-color: gray; */
  padding: 5Px;
  width: 80px;
  text-align: center;
  color:white;
  text-decoration: none;
  font-weight:700 ;
}

.rotate {
  rotate: 180deg ;
}

.next-numbers {
  gap: 20px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

#active {
  text-decoration: underline;
  font-weight: 900;
}

.next-numbers > a {
  color: #6E4317;
  text-decoration: none;
  font-weight: 600;
}

.container {
  display: grid;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.container > h2 {
  text-align: center;
  font-weight: 700;
  font-size: 60px;
}

.contact-form {
  margin-top: 50px;
  display: grid;
  margin-bottom: 200px;
  height: 300px;
  justify-content:  center ;
  width: 600px ;
}

.contact-form > input, textarea, button {
  padding: 10px;
  margin: 5px 0;
  width: 500px ;
  background-color: #F5EFE8;
  border: 2px solid #6E4317;
}

.contact-form > button {
  margin-top: 5px;
  background-color:#8B6A4B ;
  color: #F5EFE8;
  border: none;
  font-weight: 700;
}

.contact-form > button:hover {
  background-color:#6E4317 ;
}

.howto-img {
  width: 700px ;
}

textarea {
  padding-bottom: 100px;
}

}